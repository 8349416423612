import axios from 'axios';
import { API_BASE_URL, API_AUTH_TOKEN } from '@/constants';

export const serverApi = axios.create({
  baseURL: `${API_BASE_URL}`,
});

serverApi.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      Authorization: 'Bearer ' + API_AUTH_TOKEN,
    },
  }),
  (error) => Promise.reject(error)
);

serverApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);
