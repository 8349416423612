
import { defineComponent } from 'vue';
import { eventDetails } from '@/constants';

export default defineComponent({
  data: () => ({
    eventName: eventDetails.event,
    eventDate: eventDetails.date,
    eventLocation: eventDetails.location,
  }),
});
