
import { defineComponent } from 'vue';
import { claimCredential, veridaHelper } from '@/api';
import AppHeader from '@/components/AppHeader.vue';
import EventCard from '@/components/EventCard.vue';

export default defineComponent({
  name: 'HomeView',
  components: { AppHeader, EventCard },
  data: () => ({
    isProcessing: false,
    isConnected: false,
    response: {
      status: '',
      message: '',
    },
  }),
  methods: {
    setAppStatus() {
      this.isConnected = !this.isConnected;
    },
    async claimCredential() {
      this.isProcessing = true;
      try {
        await claimCredential(veridaHelper.did);
        this.response = {
          status: 'success',
          message: `Your credential has been successfully sent, check your Verida Vault`,
        };
      } catch (error) {
        this.response = {
          status: 'error',
          message: `There was an error sending your credential`,
        };
      } finally {
        this.isProcessing = false;
      }
    },
  },
});
